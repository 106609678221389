import React, { useRef } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const OpenSourceContainer = styled(motion.div)`
  padding: 80px 0 0;
  overflow-x: hidden;
`;

const QuickLaunchSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 3rem 2rem;
  text-align: center;
`;

const QuickLaunchTitle = styled.h1`
  font-size: 3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const QuickLaunchSubtitle = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const QuickLaunchGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 400px));
  gap: 2rem;
  max-width: 900px;
  margin: 0 auto;
`;

const QuickLaunchCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
`;

const CardDescription = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1rem;
  line-height: 1.5;
  margin: 0;
`;

const QuickLaunchButton = styled(Link)`
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
    transform: translateY(-2px);
  }
`;

const QuickLaunchExternalButton = styled.a`
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
    transform: translateY(-2px);
  }
`;

const Separator = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  margin: 4rem 0 2rem;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;

const InfoButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.9);
  border: none;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }
`;

const ToolSection = styled.div`
  padding: 4rem 0;
`;

const ToolHeader = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  text-align: center;
  margin-bottom: 3rem;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ContentSection = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column !important;
    text-align: center;
    padding: 4rem 2rem;
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.05);
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`;

const PlaceholderImage = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.3);
  font-size: 1.2rem;
  padding: 2rem;
  text-align: center;
`;

const TextContent = styled.div`
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  
  &:before {
    content: "•";
    color: #fff;
    margin-right: 0.8rem;
  }
`;

function OpenSource() {
  const geoJsonRef = useRef<HTMLDivElement>(null);
  const riskVisualizerRef = useRef<HTMLDivElement>(null);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <OpenSourceContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <QuickLaunchSection>
        <QuickLaunchTitle>Open Source Tools</QuickLaunchTitle>
        <QuickLaunchSubtitle>
          All our tools run entirely in your browser with no data storage or transmission
        </QuickLaunchSubtitle>
        <QuickLaunchGrid>
          <QuickLaunchCard
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
          >
            <CardTitle>GeoJSON Builder</CardTitle>
            <CardDescription>
              Create and edit geographical data with our intuitive map-based tool. Export the GeoJSON data with copy+paste or download options.
            </CardDescription>
            <ButtonGroup>
              <QuickLaunchButton to="/tools/geojson-builder">
                Launch Builder
              </QuickLaunchButton>
              <InfoButton onClick={() => scrollToSection(geoJsonRef)}>
                More Info
              </InfoButton>
            </ButtonGroup>
          </QuickLaunchCard>

          <QuickLaunchCard
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.2 }}
          >
            <CardTitle>Risk Visualizer</CardTitle>
            <CardDescription>
              Create visual reports by pinning events, news, and insights to locations. Perfect for stakeholder presentations.
            </CardDescription>
            <ButtonGroup>
              <QuickLaunchExternalButton href="/tools/visual-reporter/">
                Launch Visualiser
              </QuickLaunchExternalButton>
              <InfoButton onClick={() => scrollToSection(riskVisualizerRef)}>
                More Info
              </InfoButton>
            </ButtonGroup>
          </QuickLaunchCard>
        </QuickLaunchGrid>
      </QuickLaunchSection>

      <Separator />

      <ToolSection ref={geoJsonRef}>
        <ToolHeader>GeoJSON Builder</ToolHeader>
        <ContentSection
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <ImageContainer
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <StyledImage src="/img-1.png" alt="GeoJSON Builder Interface" />
          </ImageContainer>
          <TextContent>
            <SectionTitle>Intuitive Drawing Tools</SectionTitle>
            <Description>
              Create complex geographical shapes with ease using our intuitive drawing tools. 
              Perfect for defining regions, marking points of interest, and creating custom map overlays.
            </Description>
            <FeatureList>
              <FeatureItem>Draw lines, polygons, and points</FeatureItem>
              <FeatureItem>Real-time GeoJSON output</FeatureItem>
              <FeatureItem>Multiple base map styles</FeatureItem>
            </FeatureList>
          </TextContent>
        </ContentSection>

        <ContentSection
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <ImageContainer
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <StyledImage src="/img-2.png" alt="GeoJSON Builder Features" />
          </ImageContainer>
          <TextContent>
            <SectionTitle>Powerful Features</SectionTitle>
            <Description>
              Our GeoJSON Builder comes packed with features that make geographical data creation 
              and editing a breeze. From simple markers to complex polygons, create exactly what you need.
            </Description>
            <FeatureList>
              <FeatureItem>Export to multiple formats</FeatureItem>
              <FeatureItem>Advanced editing capabilities</FeatureItem>
              <FeatureItem>Customizable styling options</FeatureItem>
            </FeatureList>
          </TextContent>
        </ContentSection>
      </ToolSection>

      <Separator />

      <ToolSection ref={riskVisualizerRef}>
        <ToolHeader>Risk Visualizer</ToolHeader>
        <ContentSection
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <ImageContainer
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <PlaceholderImage>Risk Visualizer Interface</PlaceholderImage>
          </ImageContainer>
          <TextContent>
            <SectionTitle>Visual Risk Reporting</SectionTitle>
            <Description>
              Create compelling visual reports by pinning events, news, and insights directly to their geographical locations.
              Perfect for communicating risk events and issues to stakeholders through an intuitive map interface.
            </Description>
            <FeatureList>
              <FeatureItem>Pin events and news to locations</FeatureItem>
              <FeatureItem>Add images with descriptive text</FeatureItem>
              <FeatureItem>Smart arrow connections to content</FeatureItem>
            </FeatureList>
          </TextContent>
        </ContentSection>

        <ContentSection
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        >
          <ImageContainer
            initial={{ scale: 0.8, opacity: 0 }}
            whileInView={{ scale: 1, opacity: 1 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <PlaceholderImage>Risk Visualizer Features</PlaceholderImage>
          </ImageContainer>
          <TextContent>
            <SectionTitle>Presentation Ready</SectionTitle>
            <Description>
              Generate professional-looking risk reports and presentations with ease. The Risk Visualizer 
              helps you create clear, engaging visualizations that effectively communicate complex situations.
            </Description>
            <FeatureList>
              <FeatureItem>Export for presentations</FeatureItem>
              <FeatureItem>Multiple items per map</FeatureItem>
              <FeatureItem>Customizable layouts</FeatureItem>
            </FeatureList>
          </TextContent>
        </ContentSection>
      </ToolSection>
    </OpenSourceContainer>
  );
}

export default OpenSource;
