import React, { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const LayoutContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  background: #000;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI', Roboto, sans-serif;
`;

const Header = styled(motion.header)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  background: rgba(0, 0, 0, 0.85);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

const Logo = styled(Link)`
  font-size: 1.25rem;
  font-weight: 500;
  margin: 0;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  letter-spacing: -0.5px;
  text-decoration: none;
  
  &:hover, &:focus, &:active {
    background: linear-gradient(135deg, #fff 0%, #888 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: none;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 2rem;
`;

const NavLink = styled(Link)`
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 500;
  letter-spacing: -0.2px;
  transition: all 0.2s ease;
  position: relative;
  padding: 0.5rem 0;

  &[data-active="true"] {
    color: #fff;
    
    &:after {
      transform: scaleX(1);
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: #fff;
    transform: scaleX(0);
    transition: transform 0.2s ease;
  }

  &:hover {
    color: #fff;
    &:after {
      transform: scaleX(1);
    }
  }
`;

const PageContainer = styled(motion.main)`
  position: relative;
  min-height: 100vh;
  padding-top: 64px; /* Height of the header */
`;

function Layout() {
  const location = useLocation();

  // Scroll to top when route changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <LayoutContainer>
      <Header
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <Logo to="/">Europa Risk</Logo>
        <Nav>
          <NavLink 
            to="/"
            data-active={location.pathname === '/'}
          >
            Home
          </NavLink>
          <NavLink 
            to="/products"
            data-active={location.pathname === '/products'}
          >
            Products
          </NavLink>
          <NavLink 
            to="/open-source"
            data-active={location.pathname === '/open-source'}
          >
            Open Source
          </NavLink>
          <NavLink 
            to="/about"
            data-active={location.pathname === '/about'}
          >
            About
          </NavLink>
          <NavLink 
            to="/contact"
            data-active={location.pathname === '/contact'}
          >
            Contact
          </NavLink>
        </Nav>
      </Header>
      <PageContainer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Outlet />
      </PageContainer>
    </LayoutContainer>
  );
}

export default Layout;
