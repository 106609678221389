import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const ProductsContainer = styled(motion.div)`
  padding: 80px 0 0;
  overflow-x: hidden;
`;

const HeroSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 4rem 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const Separator = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.1) 50%,
    transparent 100%
  );
  margin: 4rem 0 2rem;
`;

const ContentSection = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column !important;
    text-align: center;
    padding: 4rem 2rem;
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.05);
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlaceholderImage = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.3);
  font-size: 1.2rem;
`;

const TextContent = styled.div`
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const FeatureItem = styled.li`
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  
  &:before {
    content: "•";
    color: #fff;
    margin-right: 0.8rem;
  }
`;

const CTASection = styled.div`
  text-align: center;
  padding: 4rem 2rem;
  background: rgba(255, 255, 255, 0.05);
`;

const CTATitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const CTADescription = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto 2rem;
`;

const CTAButton = styled(motion.button)`
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
    transform: translateY(-2px);
  }
`;

function Products() {
  const navigate = useNavigate();

  return (
    <ProductsContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeroSection>
        <Title>Enterprise Solutions</Title>
        <Subtitle>
          Advanced risk management solutions designed to protect and optimize your global operations.
          Our enterprise-grade software combines powerful analytics with intuitive interfaces.
        </Subtitle>
      </HeroSection>

      <ContentSection
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <PlaceholderImage>Global Risk Mapping Interface</PlaceholderImage>
        </ImageContainer>
        <TextContent>
          <SectionTitle>GEMS - Global Enterprise Management System</SectionTitle>
          <Description>
            GEMS is a comprehensive risk management solution that enables organizations to effectively 
            manage and assess risks across their global operations. This powerful system combines 
            asset management, geographical risk assessment, and barrier analysis in one intuitive platform.
          </Description>
          <FeatureList>
            <FeatureItem>Asset and location risk mapping across global operations</FeatureItem>
            <FeatureItem>Country-specific risk zone definition and rating system</FeatureItem>
            <FeatureItem>Advanced risk scenario modeling and analysis</FeatureItem>
          </FeatureList>
        </TextContent>
      </ContentSection>

      <ContentSection
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <PlaceholderImage>Risk Analysis Dashboard</PlaceholderImage>
        </ImageContainer>
        <TextContent>
          <SectionTitle>Intelligent Risk Analysis</SectionTitle>
          <Description>
            Our advanced analytics engine processes complex risk data to provide actionable insights.
            Visualize risks, track metrics, and generate comprehensive reports with ease.
          </Description>
          <FeatureList>
            <FeatureItem>Automated risk matrix generation</FeatureItem>
            <FeatureItem>Real-time risk monitoring and alerts</FeatureItem>
            <FeatureItem>Customizable reporting and analytics</FeatureItem>
          </FeatureList>
        </TextContent>
      </ContentSection>

      <ContentSection
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <PlaceholderImage>Barrier Management Interface</PlaceholderImage>
        </ImageContainer>
        <TextContent>
          <SectionTitle>Comprehensive Barrier Management</SectionTitle>
          <Description>
            Implement and monitor protective barriers across your organization. Track effectiveness,
            manage maintenance, and ensure continuous protection of your assets.
          </Description>
          <FeatureList>
            <FeatureItem>Barrier assignment and management for asset protection</FeatureItem>
            <FeatureItem>Performance monitoring and maintenance tracking</FeatureItem>
            <FeatureItem>Integrated compliance and audit tools</FeatureItem>
          </FeatureList>
        </TextContent>
      </ContentSection>

      <Separator />

      <CTASection>
        <CTATitle>Ready to Transform Your Risk Management?</CTATitle>
        <CTADescription>
          Experience how GEMS can revolutionize your organization's approach to risk management.
          Schedule a personalized demo with our team today.
        </CTADescription>
        <CTAButton
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => navigate('/contact')}
        >
          Request Demo
        </CTAButton>
      </CTASection>
    </ProductsContainer>
  );
}

export default Products;
