import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import About from './pages/About';
import Products from './pages/Products';
import OpenSource from './pages/OpenSource';
import Contact from './pages/Contact';
import GeoJsonBuilderEmbed from './components/GeoJsonBuilderEmbed';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="products" element={<Products />} />
          <Route path="open-source" element={<OpenSource />} />
          <Route path="contact" element={<Contact />} />
          <Route path="tools/geojson-builder" element={<GeoJsonBuilderEmbed />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
