import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutContainer = styled(motion.div)`
  padding: 80px 0 0;
  overflow-x: hidden;
`;

const HeroSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 4rem 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentSection = styled(motion.div)`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;

  &:nth-child(odd) {
    flex-direction: row-reverse;
  }

  @media (max-width: 1024px) {
    flex-direction: column !important;
    text-align: center;
    padding: 4rem 2rem;
  }
`;

const ImageContainer = styled(motion.div)`
  flex: 1;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.05);
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlaceholderImage = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.3);
  font-size: 1.2rem;
  padding: 2rem;
  text-align: center;
`;

const TextContent = styled.div`
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

const ValuesSection = styled.div`
  padding: 6rem 2rem;
  background: rgba(255, 255, 255, 0.02);
`;

const ValuesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1400px;
  margin: 3rem auto 0;
  padding: 0 1rem;
`;

const ValueCard = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ValueTitle = styled.h3`
  color: #fff;
  font-size: 1.5rem;
  margin: 0;
`;

const ValueText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.8;
  font-size: 1.1rem;
  margin: 0;
`;

function About() {
  return (
    <AboutContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeroSection>
        <Title>About Europa Risk</Title>
        <Subtitle>
          Revolutionizing how organizations understand and manage global risks through 
          innovative technology and expert analysis.
        </Subtitle>
      </HeroSection>

      <ContentSection
        initial={{ opacity: 0, x: -100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <PlaceholderImage>Global Risk Analysis Team</PlaceholderImage>
        </ImageContainer>
        <TextContent>
          <SectionTitle>Our Mission</SectionTitle>
          <Description>
            We're dedicated to democratizing risk analysis tools while providing enterprise-grade solutions 
            for complex organizational needs. Our mission is to make risk management more accessible, 
            efficient, and effective for organizations worldwide.
          </Description>
          <Description>
            By combining expertise in risk analysis with cutting-edge technology, we create solutions 
            that empower organizations to make better decisions and operate more safely in an 
            increasingly complex global environment.
          </Description>
        </TextContent>
      </ContentSection>

      <ValuesSection>
        <SectionTitle style={{ textAlign: 'center' }}>Our Values</SectionTitle>
        <ValuesGrid>
          <ValueCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5 }}
          >
            <ValueTitle>Innovation</ValueTitle>
            <ValueText>
              We continuously push the boundaries of what's possible in risk analysis, 
              developing new tools and approaches that make complex analysis more accessible.
              Our commitment to innovation drives every aspect of our work.
            </ValueText>
          </ValueCard>
          <ValueCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            <ValueTitle>Accessibility</ValueTitle>
            <ValueText>
              We believe in making powerful risk analysis tools available to everyone, 
              from enterprise clients to individual analysts. Our open-source initiatives 
              ensure essential tools are accessible to the wider community.
            </ValueText>
          </ValueCard>
          <ValueCard
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <ValueTitle>Transparency</ValueTitle>
            <ValueText>
              Our commitment to open source development and clear communication ensures 
              our tools and methods are trustworthy and verifiable. We believe transparency 
              builds trust and fosters innovation.
            </ValueText>
          </ValueCard>
        </ValuesGrid>
      </ValuesSection>

      <ContentSection
        initial={{ opacity: 0, x: 100 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true, amount: 0.2 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <ImageContainer
          initial={{ scale: 0.8, opacity: 0 }}
          whileInView={{ scale: 1, opacity: 1 }}
          viewport={{ once: true, amount: 0.2 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          <PlaceholderImage>Risk Management Solutions</PlaceholderImage>
        </ImageContainer>
        <TextContent>
          <SectionTitle>Our Approach</SectionTitle>
          <Description>
            We take a dual approach to risk management solutions. Our enterprise product, GEMS, 
            provides comprehensive risk management capabilities for complex organizational needs.
          </Description>
          <Description>
            Meanwhile, our open-source initiatives ensure that essential risk visualization tools 
            are available to the wider community, fostering innovation and collaboration in the field.
            This balanced approach allows us to serve both enterprise clients and contribute to the 
            broader risk management community.
          </Description>
        </TextContent>
      </ContentSection>
    </AboutContainer>
  );
}

export default About;
