import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import styled from 'styled-components';
import 'mapbox-gl/dist/mapbox-gl.css';

// Set Mapbox access token
mapboxgl.accessToken = 'pk.eyJ1Ijoib3R0b2NyIiwiYSI6ImNsdHl2ajRjcjBsaHgya29qbnpuaWZ6dHkifQ.vy0iuFZXvViWOqVFHqnEbw';

const GlobeWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: radial-gradient(circle at 50% 50%, #1a1a1a 0%, #000000 100%);
`;

const MapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: grab;
  
  &:active {
    cursor: grabbing;
  }
`;

interface Point {
  lat: number;
  lng: number;
  size: number;
}

interface RiskZone {
  name: string;
  coordinates: [number, number][];
  color: string;
}

const points: Point[] = [
  { lat: 51.5074, lng: -0.1278, size: 6 }, // London
  { lat: 40.7128, lng: -74.0060, size: 6 }, // New York
  { lat: 35.6762, lng: 139.6503, size: 6 }, // Tokyo
  { lat: 48.8566, lng: 2.3522, size: 6 }, // Paris
  { lat: 55.7558, lng: 37.6173, size: 6 }, // Moscow
  { lat: 31.2304, lng: 121.4737, size: 6 }, // Shanghai
  { lat: 22.3193, lng: 114.1694, size: 6 }, // Hong Kong
  { lat: 1.3521, lng: 103.8198, size: 6 }, // Singapore
  { lat: -33.8688, lng: 151.2093, size: 6 }, // Sydney
  { lat: 25.2048, lng: 55.2708, size: 6 }, // Dubai
  { lat: -23.5505, lng: -46.6333, size: 6 }, // Sao Paulo
  { lat: 19.4326, lng: -99.1332, size: 6 }, // Mexico City
  { lat: 28.6139, lng: 77.2090, size: 6 }, // New Delhi
  { lat: -26.2041, lng: 28.0473, size: 6 }, // Johannesburg
  { lat: 52.5200, lng: 13.4050, size: 6 }, // Berlin
  { lat: 41.9028, lng: 12.4964, size: 6 }, // Rome
  { lat: -34.6037, lng: -58.3816, size: 6 }, // Buenos Aires
  { lat: 37.5665, lng: 126.9780, size: 6 }, // Seoul
  { lat: 39.9042, lng: 116.4074, size: 6 }, // Beijing
  { lat: 13.7563, lng: 100.5018, size: 6 }, // Bangkok
  { lat: 45.4215, lng: -75.6972, size: 6 }, // Ottawa
  { lat: -35.2809, lng: 149.1300, size: 6 }, // Canberra
  { lat: 59.9139, lng: 10.7522, size: 6 }, // Oslo
  { lat: 59.3293, lng: 18.0686, size: 6 }, // Stockholm
  { lat: 55.6761, lng: 12.5683, size: 6 }, // Copenhagen
  { lat: -41.2866, lng: 174.7756, size: 6 }, // Wellington
  { lat: 4.7100, lng: -74.0721, size: 6 }, // Bogota
  { lat: -12.0464, lng: -77.0428, size: 6 }, // Lima
  { lat: -33.4489, lng: -70.6693, size: 6 }, // Santiago
  { lat: 14.5995, lng: 120.9842, size: 6 }  // Manila
];

const riskZones: RiskZone[] = [
  {
    name: 'Red Sea - Yemen Conflict',
    coordinates: [
      [41.771, 13.006],
      [42.500, 12.800],
      [43.500, 12.500],
      [44.200, 12.600],
      [44.800, 12.800],
      [45.300, 12.900],
      [45.917, 13.006],
      [45.917, 14.200],
      [45.917, 15.000],
      [45.917, 15.800],
      [45.200, 15.600],
      [44.500, 15.500],
      [43.800, 15.400],
      [43.200, 15.200],
      [42.500, 15.400],
      [41.771, 15.800]
    ],
    color: '#ff2200'
  },
  {
    name: 'Syrian Civil War Zone',
    coordinates: [
      [35.700, 37.200],
      [36.500, 37.150],
      [37.300, 37.100],
      [38.000, 37.050],
      [38.500, 37.000],
      [39.200, 37.100],
      [40.000, 37.200],
      [41.000, 37.150],
      [42.000, 37.100],
      [42.200, 36.500],
      [42.200, 35.800],
      [42.000, 35.200],
      [41.800, 34.500],
      [41.400, 34.000],
      [41.000, 33.500],
      [40.000, 33.200],
      [39.000, 32.800],
      [38.200, 32.500],
      [37.400, 32.400],
      [36.500, 32.300]
    ],
    color: '#ff2200'
  },
  {
    name: 'Sahel Region - Mali and Niger',
    coordinates: [
      [-12.000, 18.000],
      [-10.000, 18.200],
      [-8.000, 18.300],
      [-6.000, 18.200],
      [-4.000, 18.000],
      [-2.000, 18.100],
      [0.000, 18.200],
      [2.000, 18.300],
      [4.000, 18.200],
      [6.000, 18.100],
      [8.000, 18.000],
      [10.000, 18.100],
      [12.000, 18.000],
      [12.000, 16.000],
      [10.000, 16.000],
      [8.000, 15.800],
      [6.000, 15.600],
      [4.000, 15.400],
      [2.000, 15.200],
      [0.000, 15.000],
      [-2.000, 14.800],
      [-4.000, 14.600],
      [-6.000, 14.400],
      [-8.000, 14.200],
      [-10.000, 14.100],
      [-12.000, 14.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Ethiopian Tigray Conflict Zone',
    coordinates: [
      [36.500, 14.500],
      [37.000, 14.500],
      [37.500, 14.500],
      [38.000, 14.500],
      [38.500, 14.500],
      [39.000, 14.500],
      [39.500, 14.500],
      [40.000, 14.000],
      [40.000, 13.500],
      [40.000, 13.000],
      [40.000, 12.500],
      [39.500, 12.000],
      [39.000, 12.000],
      [38.500, 12.000],
      [38.000, 12.000],
      [37.500, 12.000],
      [37.000, 12.000],
      [36.500, 12.000],
      [36.500, 12.500],
      [36.500, 13.000],
      [36.500, 13.500],
      [36.500, 14.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Kashmir Conflict Zone',
    coordinates: [
      [73.000, 36.000],
      [73.500, 36.000],
      [74.000, 36.000],
      [74.500, 36.000],
      [75.000, 36.000],
      [75.500, 36.000],
      [76.000, 36.000],
      [76.500, 36.000],
      [77.000, 36.000],
      [77.000, 35.500],
      [77.000, 35.000],
      [77.000, 34.500],
      [77.000, 34.000],
      [77.000, 33.500],
      [77.000, 33.000],
      [77.000, 32.500],
      [77.000, 32.000],
      [76.000, 32.000],
      [75.000, 32.000],
      [74.000, 32.000],
      [73.000, 32.000],
      [73.000, 33.000],
      [73.000, 34.000],
      [73.000, 35.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Myanmar Conflict Zones',
    coordinates: [
      [92.000, 28.000],
      [93.000, 28.000],
      [94.000, 28.000],
      [95.000, 28.000],
      [96.000, 28.000],
      [97.000, 28.000],
      [98.000, 28.000],
      [99.000, 28.000],
      [100.000, 28.000],
      [101.000, 28.000],
      [101.000, 26.000],
      [101.000, 24.000],
      [101.000, 22.000],
      [101.000, 20.000],
      [99.000, 20.000],
      [97.000, 20.000],
      [95.000, 20.000],
      [93.000, 20.000],
      [92.000, 20.000],
      [92.000, 22.000],
      [92.000, 24.000],
      [92.000, 26.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Sudan Conflict Zone',
    coordinates: [
      [29.500, 16.000],
      [30.500, 16.500],
      [31.500, 16.500],
      [32.500, 16.000],
      [33.500, 15.500],
      [33.500, 15.000],
      [33.000, 14.500],
      [32.000, 14.000],
      [31.000, 13.500],
      [30.000, 13.000],
      [29.000, 12.500],
      [28.500, 12.500],
      [28.500, 13.500],
      [28.800, 14.500],
      [29.000, 15.000],
      [29.500, 16.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Israel and Gaza Conflict Zone',
    coordinates: [
      [31.200, 34.200],
      [31.400, 34.400],
      [31.600, 34.600],
      [31.700, 34.800],
      [31.800, 35.000],
      [31.900, 35.200],
      [31.800, 35.400],
      [31.600, 35.500],
      [31.400, 35.600],
      [31.200, 35.500],
      [31.100, 35.300],
      [31.000, 35.100],
      [31.000, 34.900],
      [31.000, 34.700],
      [31.100, 34.500],
      [31.200, 34.200]
    ],
    color: '#ff2200'
  },
  {
    name: 'Mozambique Cabo Delgado Terrorism Zone',
    coordinates: [
      [-10.500, 39.000],
      [-10.300, 39.500],
      [-10.100, 40.000],
      [-10.000, 40.500],
      [-10.500, 41.000],
      [-11.000, 41.000],
      [-11.500, 40.500],
      [-12.000, 40.000],
      [-12.200, 39.500],
      [-12.000, 39.000],
      [-11.500, 38.500],
      [-11.000, 38.500],
      [-10.500, 39.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Armenia-Azerbaijan Conflict Zone',
    coordinates: [
      [45.000, 40.500],
      [45.500, 40.500],
      [46.000, 40.300],
      [46.500, 40.200],
      [47.000, 40.000],
      [47.000, 39.500],
      [46.800, 39.000],
      [46.500, 38.800],
      [46.000, 38.700],
      [45.500, 38.800],
      [45.000, 39.000],
      [44.800, 39.500],
      [45.000, 40.000],
      [45.000, 40.500]
    ],
    color: '#ff2200'
  },
  {
    name: 'Northern Nigeria Terrorism Zone',
    coordinates: [
      [11.000, 14.000],
      [11.500, 14.200],
      [12.000, 14.500],
      [12.500, 14.300],
      [13.000, 14.000],
      [13.200, 13.500],
      [13.000, 13.000],
      [12.500, 12.800],
      [12.000, 12.500],
      [11.500, 12.300],
      [11.000, 12.000],
      [10.800, 12.500],
      [10.800, 13.000],
      [11.000, 14.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Port-au-Prince Unrest Zone',
    coordinates: [
      [-72.500, 18.700],
      [-72.400, 18.650],
      [-72.300, 18.600],
      [-72.200, 18.500],
      [-72.200, 18.400],
      [-72.300, 18.300],
      [-72.400, 18.200],
      [-72.500, 18.150],
      [-72.600, 18.200],
      [-72.700, 18.300],
      [-72.750, 18.400],
      [-72.750, 18.500],
      [-72.700, 18.600],
      [-72.600, 18.650],
      [-72.500, 18.700]
    ],
    color: '#ff2200'
  },
  {
    name: 'South China Sea Dispute Zone',
    coordinates: [
      [109.000, 21.000],
      [110.000, 21.000],
      [111.000, 21.000],
      [112.000, 21.000],
      [113.000, 21.000],
      [114.000, 21.000],
      [115.000, 21.000],
      [116.000, 21.000],
      [117.000, 21.000],
      [118.000, 21.000],
      [119.000, 21.000],
      [120.000, 21.000],
      [121.000, 21.000],
      [121.000, 19.000],
      [121.000, 17.000],
      [121.000, 15.000],
      [119.000, 15.000],
      [117.000, 15.000],
      [115.000, 15.000],
      [113.000, 15.000],
      [111.000, 15.000],
      [109.000, 15.000],
      [109.000, 17.000],
      [109.000, 19.000]
    ],
    color: '#ff2200'
  },
  {
    name: 'Ukraine Conflict Zone - Eastern Front',
    coordinates: [
      [36.000, 49.000],
      [36.500, 49.000],
      [37.000, 49.000],
      [37.500, 49.000],
      [38.000, 49.000],
      [38.500, 49.000],
      [39.000, 49.000],
      [39.500, 49.000],
      [40.000, 49.000],
      [40.000, 48.500],
      [40.000, 48.000],
      [40.000, 47.500],
      [40.000, 47.000],
      [39.500, 47.000],
      [39.000, 47.000],
      [38.500, 47.000],
      [38.000, 47.000],
      [37.500, 47.000],
      [37.000, 47.000],
      [36.500, 47.000],
      [36.000, 47.000],
      [36.000, 47.500],
      [36.000, 48.000],
      [36.000, 48.500]
    ],
    color: '#ff2200'
  },
  {
    name: 'Taiwan Strait Tension Zone',
    coordinates: [
      [119.000, 25.000],
      [119.500, 25.000],
      [120.000, 25.000],
      [120.500, 25.000],
      [121.000, 25.000],
      [121.500, 25.000],
      [122.000, 25.000],
      [122.500, 25.000],
      [123.000, 25.000],
      [123.000, 24.500],
      [123.000, 24.000],
      [123.000, 23.500],
      [123.000, 23.000],
      [123.000, 22.500],
      [123.000, 22.000],
      [122.000, 22.000],
      [121.000, 22.000],
      [120.000, 22.000],
      [119.000, 22.000],
      [119.000, 22.500],
      [119.000, 23.000],
      [119.000, 23.500],
      [119.000, 24.000],
      [119.000, 24.500]
    ],
    color: '#ff2200'
  }
];

function GlobeComponent() {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const rotationTimer = useRef<number | null>(null);
  const isInteracting = useRef(false);
  const lastFrameTime = useRef<number>(0);
  const rotationSpeed = useRef<number>(0.05);

  useEffect(() => {
    if (!mapContainer.current) return;

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/light-v11',
      center: [25, 25],
      zoom: 2.5,
      projection: 'globe',
      customAttribution: 'Europa Risk',
      dragRotate: true,
      pitchWithRotate: true,
      touchZoomRotate: true,
      interactive: true,
      pitch: 0,
      bearing: 0,
      renderWorldCopies: true,
      maxZoom: 20,
      minZoom: 1.5,
      antialias: true,
      fadeDuration: 0,
      preserveDrawingBuffer: true
    });

    const startRotation = () => {
      if (rotationTimer.current || isInteracting.current) return;
      
      const rotate = (currentTime: number) => {
        if (!map.current || isInteracting.current) {
          if (rotationTimer.current) {
            cancelAnimationFrame(rotationTimer.current);
            rotationTimer.current = null;
          }
          return;
        }

        const deltaTime = currentTime - lastFrameTime.current;
        lastFrameTime.current = currentTime;

        if (deltaTime > 0) {
          const center = map.current.getCenter();
          center.lng -= rotationSpeed.current * (deltaTime / 16.67);
          map.current.easeTo({ 
            center, 
            duration: 0,
            easing: t => t
          });
        }

        rotationTimer.current = requestAnimationFrame(rotate);
      };

      lastFrameTime.current = performance.now();
      rotate(lastFrameTime.current);
    };

    const stopRotation = () => {
      isInteracting.current = true;
      if (rotationTimer.current) {
        cancelAnimationFrame(rotationTimer.current);
        rotationTimer.current = null;
      }
    };

    const resumeRotation = () => {
      isInteracting.current = false;
      lastFrameTime.current = performance.now();
      setTimeout(startRotation, 1500);
    };

    const setupMap = () => {
      if (!map.current) return;

      map.current.setFog({
        'horizon-blend': 0.15,
        'space-color': '#000000',
        'star-intensity': 0.2
      });

      // Add risk zones
      map.current.addSource('risk-zones', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: riskZones.map(zone => ({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [...zone.coordinates, zone.coordinates[0]]
              ]
            },
            properties: {
              name: zone.name,
              color: zone.color
            }
          }))
        }
      });

      map.current.addLayer({
        id: 'risk-zones',
        type: 'fill',
        source: 'risk-zones',
        paint: {
          'fill-color': ['get', 'color'],
          'fill-opacity': 0.2
        }
      });

      map.current.addLayer({
        id: 'risk-zones-border',
        type: 'line',
        source: 'risk-zones',
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 2,
          'line-opacity': 0.4
        }
      });

      // Add points
      map.current.addSource('points', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: points.map(point => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [point.lng, point.lat]
            },
            properties: {
              size: point.size
            }
          }))
        }
      });

      map.current.addLayer({
        id: 'points',
        type: 'circle',
        source: 'points',
        paint: {
          'circle-radius': ['get', 'size'],
          'circle-color': '#ff4444',
          'circle-opacity': 1,
          'circle-blur': 0,
          'circle-stroke-width': 2,
          'circle-stroke-color': 'rgba(255, 255, 255, 0.3)'
        }
      });

      map.current.addLayer({
        id: 'points-glow',
        type: 'circle',
        source: 'points',
        paint: {
          'circle-radius': ['*', ['get', 'size'], 3],
          'circle-color': '#ff4444',
          'circle-opacity': 0.15,
          'circle-blur': 1
        }
      });

      // Event listeners for interaction
      const events = ['mousedown', 'dragstart', 'touchstart', 'wheel'];
      events.forEach(event => map.current?.on(event, stopRotation));

      const endEvents = ['mouseup', 'dragend', 'touchend', 'wheelend'];
      endEvents.forEach(event => map.current?.on(event, resumeRotation));

      // Performance optimizations
      map.current.on('render', () => {
        if (!map.current?.isMoving()) {
          map.current?.triggerRepaint();
        }
      });

      startRotation();
    };

    map.current.on('style.load', setupMap);

    return () => {
      if (rotationTimer.current) {
        cancelAnimationFrame(rotationTimer.current);
      }
      if (map.current) {
        map.current.remove();
      }
    };
  }, []);

  return (
    <GlobeWrapper>
      <MapContainer ref={mapContainer} />
    </GlobeWrapper>
  );
}

export default GlobeComponent;
