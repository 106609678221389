import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ContactContainer = styled(motion.div)`
  padding: 80px 0 0;
`;

const HeroSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  padding: 4rem 2rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(135deg, #fff 0%, #888 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Subtitle = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
`;

const ContentSection = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 6rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  align-items: start;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
  }
`;

const ContactForm = styled(motion.form)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2.5rem;
`;

const FormTitle = styled.h2`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 2rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  display: block;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 0.5rem;
  font-size: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.15);
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  color: #fff;
  font-size: 1rem;
  min-height: 150px;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.15);
  }
`;

const SubmitButton = styled(motion.button)`
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  border: none;
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s ease;

  &:hover {
    background: #fff;
    transform: translateY(-2px);
  }
`;

const ContactInfo = styled.div`
  color: rgba(255, 255, 255, 0.8);
`;

const InfoSection = styled(motion.div)`
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2.5rem;
  margin-bottom: 2rem;
`;

const InfoTitle = styled.h3`
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 1.5rem;
`;

const InfoText = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1rem;
`;

const EnterpriseSection = styled(motion.div)`
  background: linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 2.5rem;
  text-align: center;
  margin-top: 4rem;
`;

const EnterpriseTitle = styled.h2`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 1rem;
`;

const EnterpriseText = styled.p`
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 2rem;
`;

function Contact() {
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission
  };

  return (
    <ContactContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <HeroSection>
        <Title>Get in Touch</Title>
        <Subtitle>
          Have questions about our solutions or want to learn more? We're here to help.
        </Subtitle>
      </HeroSection>

      <ContentSection>
        <ContactForm
          onSubmit={handleSubmit}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <FormTitle>Send us a Message</FormTitle>
          <FormGroup>
            <Label>Name</Label>
            <Input type="text" required />
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input type="email" required />
          </FormGroup>
          <FormGroup>
            <Label>Subject</Label>
            <Input type="text" required />
          </FormGroup>
          <FormGroup>
            <Label>Message</Label>
            <TextArea required />
          </FormGroup>
          <SubmitButton
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            type="submit"
          >
            Send Message
          </SubmitButton>
        </ContactForm>

        <ContactInfo>
          <InfoSection
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <InfoTitle>General Inquiries</InfoTitle>
            <InfoText>
              For general questions about our tools, services, or company, 
              feel free to reach out using the contact form or email us directly.
            </InfoText>
            <InfoText>
              Email: info@europarisk.com
            </InfoText>
          </InfoSection>

          <InfoSection
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <InfoTitle>Technical Support</InfoTitle>
            <InfoText>
              Need help with our tools or have technical questions? Our support team 
              is ready to assist you.
            </InfoText>
            <InfoText>
              Email: support@europarisk.com
            </InfoText>
          </InfoSection>
        </ContactInfo>
      </ContentSection>

      <EnterpriseSection
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5 }}
      >
        <EnterpriseTitle>Enterprise Solutions</EnterpriseTitle>
        <EnterpriseText>
          Looking for enterprise-grade risk management solutions? Let's discuss how 
          GEMS can help your organization manage global risks more effectively.
        </EnterpriseText>
        <SubmitButton
          as="a"
          href="mailto:enterprise@europarisk.com"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          style={{ display: 'inline-block', width: 'auto' }}
        >
          Contact Enterprise Sales
        </SubmitButton>
      </EnterpriseSection>
    </ContactContainer>
  );
}

export default Contact;
