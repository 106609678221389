import React from 'react';
import styled from 'styled-components';

const BuilderContainer = styled.div`
  position: relative;
  width: 100%;
  height: calc(100vh - 80px); // Subtract header height
  margin-top: 80px; // Account for fixed header
`;

const BuilderFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  background: #fff;
`;

const GeoJsonBuilderEmbed: React.FC = () => {
  return (
    <BuilderContainer>
      <BuilderFrame 
        src="/geojson-builder/" 
        title="GeoJSON Builder"
      />
    </BuilderContainer>
  );
};

export default GeoJsonBuilderEmbed;
